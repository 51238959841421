<template>
  <div class="bg-white">
    <div class="px-4 py-5 space-y-6 sm:p-6">
      <h1 class="text-3xl lg:text-6xl font-black">CONTACT</h1>
      <img
        src="../assets/images/Monster-painting-on-the-wall.png"
        alt="Cartoon monster drawing on the wall with crayons"
        class="mx-auto"
        style="max-height: 550px;"
      />
      <form @submit.prevent="handleSubmit" class="max-w-screen-lg mx-auto">
        <p>
          Feel free to contact me by filling out the form below, or email me at
          <span class="font-black text-coolGray-700">gabrielle.nadeau@gmail.com</span>. Thank you!
        </p>
        <div class="mt-6">
          <label for="name">Name:</label>
          <input type="text" id="name" placeholder="name" ref="nameInput" />
        </div>
        <div class="mt-6">
          <label for="email">Email:</label>
          <input type="email" id="email" placeholder="email" ref="emailInput" />
        </div>
        <div class="mt-6">
          <label for="message">Message:</label>
          <textarea
            name="message"
            id="message"
            cols="30"
            rows="10"
            class="shadow-sm focus:ring-cyan-600 focus:border-cyan-600 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
            ref="messageInput"
          ></textarea>
        </div>
        <div class="flex items-end">
          <BaseButton v-if="!success">
            <svg
              v-if="loading"
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            {{ loading ? 'Sending' : 'Submit' }}
          </BaseButton>
          <p class="text-red-500 font-bold ml-4" v-if="!success">{{ error ? error : '&nbsp;' }}</p>
          <p class="text-emerald-600 font-bold mt-4" v-if="success">{{ success }}</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton'

const URL = 'https://gabriellenadeau.com/.netlify/functions/send-email'
// const URL = 'http://localhost:3000/'

export default {
  components: {
    BaseButton,
  },
  data: function() {
    return {
      loading: false,
      error: false,
      success: false,
    }
  },
  methods: {
    handleSubmit: function() {
      this.error = false
      this.loading = true
      // get form data
      const name = this.$refs.nameInput.value.trim()
      const email = this.$refs.emailInput.value.trim()
      const message = this.$refs.messageInput.value.trim()

      // validate form data
      const errors = []
      // name
      if (!name || name.length < 2) {
        errors.push('Please provide a name.')
      }

      // email
      const isValidEmail = this.validateEmail(email)
      if (!isValidEmail) {
        errors.push('Email is not valid.')
      }

      // message
      if (message.length < 10) {
        errors.push('Message is too short.')
      }

      // ERRORS FOUND
      if (errors.length !== 0) {
        let errorMessage = errors.join(' ')
        this.error = errorMessage
        this.loading = false
        return
      }

      // send data to the server
      const bodyData = {
        name,
        email,
        message,
      }

      fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
      })
        .then((res) => res.json())
        .then((data) => {
          if (!data.error) {
            // submission was a success.
            this.success = 'Message sent.'
          } else {
            // Failure.
            console.log(data.error)
            this.error = 'Server error. Please try again.'
          }
        })
        .catch((error) => {
          console.log('Fetch error', error)
          this.error = 'Server error. Please try again.'
        })
        .finally(() => {
          this.loading = false
        })
    },
    validateEmail: function(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return regex.test(email)
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  @apply block text-sm font-medium text-gray-700;
}

input {
  @apply mt-1 focus:ring-cyan-600 focus:border-cyan-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
}
</style>
